import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of Deload Week`}</em></p>
    <p>{`Skill Practice: Kipping Pullups, T2B, K2E’s`}</p>
    <p>{`then, 20:00 amrap of:`}</p>
    <p>{`15/10 Calorie Assault Bike`}</p>
    <p>{`10-T2B`}</p>
    <p>{`10-Wall Balls (20/14)(RX+ 30/20)`}</p>
    <p>{`15/10 Calorie Assault Bike`}</p>
    <p>{`10-Pullups (RX+=CTB)`}</p>
    <p>{`10-Wall Balls`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      